var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.valorAtual > _vm.valorAnterior)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","large":""}},[_vm._v(" mdi-arrow-top-right-thick ")])],1)]}}],null,false,2840090728)},[_c('span',[_vm._v(" Piora em relação ao mês anterior ")])]):(_vm.valorAtual == _vm.valorAnterior)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-minus-thick ")])],1)]}}])},[_c('span',[_vm._v(" Resultado sem evolução ")])]):(_vm.valorAtual < _vm.valorAnterior)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-arrow-bottom-right-thick ")])],1)]}}])},[_c('span',[_vm._v(" Melhora em relação ao mês anterior ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }