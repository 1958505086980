<template>
  <span>
    <v-tooltip 
      v-if="valorAtual > valorAnterior"
      bottom 
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            color="error"
            large
          >
            mdi-arrow-top-right-thick
          </v-icon>
        </span>
      </template>
      <span>
        Piora em relação ao mês anterior
      </span>
    </v-tooltip>
    <v-tooltip 
      v-else-if="valorAtual == valorAnterior"
      bottom 
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            large
          >
            mdi-minus-thick
          </v-icon>
        </span>
      </template>
      <span>
        Resultado sem evolução
      </span>
    </v-tooltip>
    <v-tooltip 
      v-else-if="valorAtual < valorAnterior"
      bottom 
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            color="success"
            large
          >
            mdi-arrow-bottom-right-thick
          </v-icon>
        </span>
      </template>
      <span>
        Melhora em relação ao mês anterior
      </span>
    </v-tooltip>
  </span>
</template>

<script>

export default {
  name: "StatusEvolucaoKpis",
  props: {
    valorAtual: {
      type: Number,
      required: true,
    },
    valorAnterior: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style></style>